<template>
  <layout-full-width :title="$tc('profile', 1)"/>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";

export default {
  name: "PageProfile",
  components: { LayoutFullWidth }
}
</script>

<style scoped lang="scss"></style>